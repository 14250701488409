import React, { createContext, useState, useEffect, useContext } from 'react';
import { CircularProgress } from '@chakra-ui/react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const parsedUser = JSON.parse(loggedInUser);
      setUser(parsedUser);
    }
    setLoading(false);

    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        const updatedUser = localStorage.getItem('user');
        setUser(updatedUser ? JSON.parse(updatedUser) : null);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('authenticated', 'true');
  };

  const logout = (redirectCallback) => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('authenticated');
    localStorage.setItem('logout', Date.now());
    if (redirectCallback) {
      redirectCallback();
    }
  };

  const register = async (username, mobile, password) => {
    try {
      const response = await fetch("https://newproject3-0-3stp.onrender.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, mobile, password }),
      });
      const data = await response.json();
      if (data.status === 'error') {
        throw new Error(data.error || "Error registering user");
      }
      const token = data.token;
      localStorage.setItem("token", token);
      const decodedToken = jwtDecode(token);
      const { name, username: userName } = decodedToken;
      localStorage.setItem("authenticated", "true");
      localStorage.setItem("user", JSON.stringify({ name, username: userName }));
      setUser({ name, username: userName });
      return data;
    } catch (error) {
      console.error("Error registering user:", error);
      throw new Error("Error registering user");
    }
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
