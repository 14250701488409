import React from 'react';
import { Heading, Grid, Box } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faMobile, faPhone, faSquare } from '@fortawesome/free-solid-svg-icons';

const cardData = [
  { title: 'Instant Deposit And Withdraw', icon: faWallet, text: 'Instant Deposit And Withdraw', color: '#FC4100' },
  { title: 'Trusted Site', icon: faMobile, text: 'Trusted Site', color: '#C40C0C' },
  { title: 'Customer Support', icon: faPhone, text: 'Customer Support', color: '#00215E' },
  { title: 'Fast Result', icon: faSquare, text: 'Unique Games', color: '#85469C' },
];

const StaticCard = ({ icon, text, color }) => {
  return (
    <Box
      bg={'white'}
      borderRadius="26px"
      width="100%"
      height={{ base: "180px", md: "260px" }} // Adjusted height for smaller screens
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      transition="transform .3s, box-shadow .3s"
      padding={6}
      boxShadow="0 4px 20px rgba(0, 0, 0, 0.15)"
      _hover={{
        transform: "translateY(-8px)",
        boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Box
        fontSize={{ base: "30px", md: "45px" }}
        marginBottom="10px"
        color={color}
        transition="transform .3s"
        _hover={{
          transform: "scale(1.2)",
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </Box>
      <Heading
        as="h3"
        fontSize={{ base: "14px", md: "18px" }}
        fontWeight="700"
        textAlign="center"
        margin="0"
        color={color}
      >
        {text}
      </Heading>
    </Box>
  );
};

const StaticCardComponent = () => {
  return (
    <Box bg={'#FFCBCB'} padding="20px">
      <Heading
        fontFamily="Hammersmith One, sans-serif"
        color={'#1E0342'}
        fontSize={{ base: '24px', md: '40px' }}
        textAlign="center"
        mb={['20px', '20px', '60px']}
      >
        Play Confidently{' '}
        <Box as="span" bgClip="text" WebkitBackgroundClip="text" color="transparent">
          VeloDice
        </Box>
      </Heading>
      <Grid
        templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
        gap={4}
        justifyContent="center"
      >
        {cardData.map((card, index) => (
          <StaticCard key={index} icon={card.icon} text={card.text} color={card.color} />
        ))}
      </Grid>
    </Box>
  );
};

export default StaticCardComponent;
