import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Grid,
  GridItem,
  Input,
  SlideFade,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Alert,
  AlertIcon,
  useToast,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import { FaCheckCircle, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { BiReset } from 'react-icons/bi';
import { jwtDecode } from 'jwt-decode';
import { Wheel } from 'react-custom-roulette';
import spinSound from './revolver-spin-96947.mp3';
import useWalletBalance from './hook/useWalletBalance';
import useWalletActions from './hook/useWalletActions';
import '../Style/WheelSpinner.css';
const data = [
  { option: '0', style: { backgroundColor: '#FF5722', textColor: 'black' } },
  { option: '1', style: { backgroundColor: '#F44336', textColor: 'black' } },
  { option: '2', style: { backgroundColor: '#4CAF50', textColor: 'black' } },
  { option: '3', style: { backgroundColor: '#FFC107', textColor: 'black' } },
  { option: '4', style: { backgroundColor: '#9C27B0', textColor: 'black' } },
  { option: '5', style: { backgroundColor: '#673AB7', textColor: 'black' } },
  { option: '6', style: { backgroundColor: '#03A9F4', textColor: 'black' } },
  { option: '7', style: { backgroundColor: '#00BCD4', textColor: 'black' } },
  { option: '8', style: { backgroundColor: '#8BC34A', textColor: 'black' } },
  { option: '9', style: { backgroundColor: '#FF9800', textColor: 'black' } }
];

const SingleNumber = () => {
  const cancelRef = useRef(); // Define cancelRef here
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [gameEnded, setGameEnded] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState({});
  const [betAmount, setBetAmount] = useState('');
  const [result, setResult] = useState(null);
  const [showPlacedMessage, setShowPlacedMessage] = useState(false);
  const [error, setError] = useState('');
  const [autoSpinAlert, setAutoSpinAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameHistory, setGameHistory] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [timer, setTimer] = useState(15);
  const [latestResult, setLatestResult] = useState(null);
  const [muted, setMuted] = useState(false);
  const [username, setUsername] = useState('');

  const { walletBalance, setWalletBalance, fetchWalletBalance } = useWalletBalance();
  const { withdrawFromWallet, depositToWallet } = useWalletActions();


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const usernameFromToken = decodedToken.username;
      setUsername(usernameFromToken);

      // Trigger initial fetch and polling via the hook
      fetchWalletBalance(usernameFromToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWalletBalance]);

  // Function to handle manual reset of the game
  const resetGame = () => {
    setSelectedNumbers({});
    setBetAmount('');
    setResult(null);
    setShowPlacedMessage(false);
    setWalletBalance(0);
    setError('');
    setGameHistory([]);
    setPrizeNumber(null);
    setLatestResult(null);
    setGameEnded(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (mustSpin) {
        event.preventDefault();
        event.returnValue = 'You have an ongoing game. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [mustSpin]);

  const updateBackendBetResult = async (isWin, winningAmount) => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Convert selectedNumbers to an array of objects with number and bet amount
        const selectedNumbersArray = Object.keys(selectedNumbers).map((number) => ({
          number: parseInt(number, 10),
          betAmount: parseInt(selectedNumbers[number], 10),
        }));

        // Calculate the total bet amount
        const totalBetAmount = selectedNumbersArray.reduce((acc, cur) => acc + cur.betAmount, 0);

        const betResponse = await fetch('https://newproject3-0-3stp.onrender.com/account/bet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username,
            amount: totalBetAmount, // Ensure the total bet amount is sent here
            type: 'Bet',
            status: 'Completed', // Ensure status is 'Completed' by default
            createdAt: new Date().toISOString(), // Ensure createdAt is a valid Date
            betResult: isWin ? 'Win' : 'Loss',
            gameName: 'Spin & Win',
            category: 'Spin',
            betAmount: totalBetAmount, // Ensure the total bet amount is sent here as well
            selectedNumbers: selectedNumbersArray, // Send the selected numbers array with bet amounts
            winningAmount: isWin ? winningAmount : 0,

          }),
        });

        if (betResponse.ok) {
          if (isWin) {
            await depositToWallet(winningAmount, true);
          }
        } else {
          const data = await betResponse.json();
          console.error('Error placing bet:', data.message);
        }
      } catch (error) {
        console.error('Error placing bet:', error.message);
        throw error; // Propagate error for proper error handling
      }
    }
  };

  const handleBetAmountChange = e => {
    setBetAmount(e.target.value);
  };

  const adjustWinProbability = (selectedNumbers = {}, walletBalance) => {
    selectedNumbers = selectedNumbers || {};
    const totalBetAmount = Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0);
    const randomNumber = Math.random();
    let winningNumber;

    // Adjust win probability based on total bet amount and wallet balance
    if (randomNumber < 0.6) {
      // 60% chance of small win (0-4)
      winningNumber = Math.floor(Math.random() * 5);
    } else if (randomNumber < 0.9) {
      // 30% chance of medium win (5-7)
      winningNumber = 5 + Math.floor(Math.random() * 3);
    } else {
      // 10% chance of large win (8-9)
      winningNumber = 8 + Math.floor(Math.random() * 2);
    }

    // Ensure small bets have a higher chance of winning
    if (totalBetAmount <= 500 && randomNumber < 0.7) {
      winningNumber = Math.floor(Math.random() * 5); // Favor small win numbers
    }

    return winningNumber;
  };

  const handleBet = (number) => {
    if (betAmount !== '') {
      setSelectedNumbers((prevSelectedNumbers) => {
        const updatedNumbers = { ...prevSelectedNumbers };
        updatedNumbers[number] = (updatedNumbers[number] || 0) + parseInt(betAmount);
        return updatedNumbers;
      });
      setError('');
    } else {
      toast({
        title: 'Enter Amount.',
        description: 'Please enter a valid bet amount and select at least one number.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSpinComplete = async () => {
    if (!mustSpin) {
      const selectedBet = parseInt(data[prizeNumber].option);
      setResult(selectedBet);
      const isWin = selectedNumbers.hasOwnProperty(selectedBet);
      const totalWinAmount = isWin ? selectedNumbers[selectedBet] * 10 : 0;

      try {
        setLoading(true);
        await updateBackendBetResult(isWin, totalWinAmount);
        setLoading(false);
      } catch (error) {
        console.error('Error updating backend with bet result:', error.message);
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Failed to update bet result. Please try again.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      if (Object.keys(selectedNumbers).length > 0 && betAmount !== '' && walletBalance >= betAmount) {
        const newHistoryEntry = {
          bet: { ...selectedNumbers },
          result: selectedBet,
          isWin,
          winningAmount: totalWinAmount,
        };

        setGameHistory((prevHistory) => [...prevHistory, newHistoryEntry]);
      }

      toast({
        title: isWin ? 'Congratulations!' : 'Better luck next time!',
        description: isWin ? `You won ₹${totalWinAmount}` : 'You lost the bet.',
        status: isWin ? 'success' : 'error',
        duration: 2000,
        isClosable: true,
        position: 'bottom',
      });

      setGameEnded(true);
    }
  };

  const handleResultUpdate = (newResult) => {
    setResult(newResult);
    const winnings = calculateWinnings(newResult);
    if (winnings > 0) {
      setWalletBalance((prevBalance) => prevBalance + winnings);
    }

    const newHistoryEntry = {
      bet: { ...selectedNumbers },
      result: newResult,
      winnings: winnings,
    };

    setGameHistory([...gameHistory, newHistoryEntry]);

    setSelectedNumbers({});
    updateBackendBetResult(newResult !== null && winnings > 0, winnings);
  };

  const placeBetConfirmation = () => {
    const totalBetAmount = Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0);

    if (Object.keys(selectedNumbers).length > 0 && totalBetAmount > 0) {
      if (walletBalance >= totalBetAmount) {
        onOpen();
        setError('');
      } else {
        toast({
          title: 'Insufficient Funds',
          description: 'Please enter a valid bet amount.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Incomplete Bet',
        description: 'Please select at least one number and enter a valid bet amount.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const placeBet = async () => {
    onClose();
    setLoading(true);
    try {
      const totalBetAmount = Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0);
      await withdrawFromWallet(totalBetAmount, true);
      setShowPlacedMessage(true);
      setTimeout(() => {
        setShowPlacedMessage(false);
      }, 2000);
      setTimeout(() => {
        if (!result) {
          handleSpinClick();
          setAutoSpinAlert(true);
        }
      }, 1000);
    } catch (error) {
      console.error('Error withdrawing from wallet:', error.message);
      setError('Failed to place bet. Please try again.');
    }
    setLoading(false);
  };

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = adjustWinProbability(); // Adjust win probability
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  const onStopSpinning = () => {
    setMustSpin(false);
    setLatestResult(prizeNumber);

    // Add a delay to simulate smooth deceleration
    setTimeout(() => {
      handleResultUpdate(prizeNumber);
      handleSpinComplete();
      setTimeout(() => {
        resetGame();
      }, 5000);
    }, 1000); // Adjust this delay to ensure a smooth stop
  };
  const calculateWinnings = (newResult) => {
    if (newResult !== null) {
      let totalWinnings = 0;
      for (let number in selectedNumbers) {
        if (parseInt(number) === newResult) {
          totalWinnings += parseInt(selectedNumbers[number]);
        }
      }
      return totalWinnings * 9; // Adjust the payout multiplier as needed
    }
    return 0;
  };

  useEffect(() => {
    if (prizeNumber !== null && prizeNumber !== undefined) {
      const timer = setTimeout(() => {
        setMustSpin(false);
        setLatestResult(prizeNumber);
        handleResultUpdate(prizeNumber);
      }, 120000); // 2 minutes in milliseconds

      return () => clearTimeout(timer);
    }
  }, [prizeNumber]);
  useEffect(() => {
    let audio;

    const playSpinSound = () => {
      audio = new Audio(spinSound);
      audio.loop = true;

      if (!muted && mustSpin) {
        audio.play().catch(error => console.error('Failed to play audio:', error));
      }
    };

    playSpinSound();

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [mustSpin, muted]);

  useEffect(() => {
    if (prizeNumber !== null && prizeNumber !== undefined) {
      const timer = setTimeout(() => {
        setMustSpin(false);
        setLatestResult(prizeNumber);
        handleResultUpdate(prizeNumber);
      }, 120000); // 2 minutes in milliseconds

      return () => clearTimeout(timer);
    }
  }, [prizeNumber]);

  const toggleMute = () => {
    setMuted(!muted);
  };
  return (
    <Flex justifyContent="center" alignItems="center" minHeight="100vh" backgroundColor="black">
      <Box
        p={{ base: '4', md: '8' }}
        boxShadow="xl"
        bg={'black'}
        w={{ base: '90%', md: '80%', lg: '70%' }}
        maxW="1500px"
        display="flex"
        flexDirection="column"
        style={{ backdropFilter: 'blur(10px)' }}
        alignItems="center"
        color={colorMode === 'light' ? 'white' : 'gray.800'}
      >
        <Heading
          as="h1"
          mb={{ base: '6', md: '10' }}
          textAlign="center"
          color={colorMode === 'light' ? 'red.600' : 'white'}
          fontSize={{ base: '3xl', md: '6xl' }}
          fontWeight="bold"
          textShadow="2px 2px 2px rgba(0,0,0,0.5)"
        >
          🎉Spin & Win🎉
        </Heading>

        <Flex direction="column" alignItems="center" position="relative">
          <div className="spinner-container">
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              backgroundColors={['#FFFFFF', '#EEEEEE']}
              onStopSpinning={onStopSpinning}
              borderWidth={8}
              innerRadius={5}
              fontSize={30}
              fontWeight={800}
          
             
            />


          </div>
          <div className="volume-icon" onClick={toggleMute}>
            {muted ? <Icon as={FaVolumeMute} boxSize={6} color="#FF7F3E" /> : <Icon as={FaVolumeUp} boxSize={6} color="#FF7F3E" />}
          </div>
        </Flex>
        {latestResult !== undefined && (
          <Text
            fontSize={{ base: '3xl', md: '6xl' }}
            fontWeight="bold"
            mt={4}
            color={latestResult === 0 ? "green.500" : "green.500"}  // Apply red color for 0, green for others
          >
            {latestResult}
          </Text>
        )}


        <Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} mt='10' gap={{ base: 4, md: 6 }} mb="8">
          {[...Array(9).keys()].map(num => (
            <GridItem key={num}>
              <SlideFade in={true}>
                <Flex flexDirection="column" alignItems="center">
                  <Button
                    onClick={() => handleBet(num + 1)}
                    size="lg"
                    variant={selectedNumbers[num + 1] !== undefined ? 'solid' : 'outline'}
                    borderRadius="full"
                    colorScheme={selectedNumbers[num + 1] !== undefined ? 'green' : 'red'}
                    isDisabled={!betAmount || mustSpin}
                    _hover={{ transform: 'scale(1.1)' }}
                    width="100px"
                  >
                    {num + 1}
                    {selectedNumbers[num + 1] && (
                      <Text mt="2" color="red.500" fontSize="sm">
                        {selectedNumbers[num + 1]}₹
                      </Text>
                    )}
                  </Button>
                </Flex>
              </SlideFade>
            </GridItem>
          ))}
          <GridItem colSpan={{ base: 1, md: 3 }} textAlign="center">
            <SlideFade in={true}>
              <Flex flexDirection="column" alignItems="center">
                <Button
                  onClick={() => handleBet(0)}
                  size="lg"
                  variant={selectedNumbers[0] !== undefined ? 'solid' : 'outline'}
                  borderRadius="full"
                  colorScheme={selectedNumbers[0] !== undefined ? 'green' : 'red'}
                  _hover={{ transform: 'scale(1.1)' }}
                  width="100px"
                  isDisabled={!betAmount || mustSpin}
                >
                  0
                  {selectedNumbers[0] && (
                    <Text mt="2" color="red.500" fontSize="sm">
                      {selectedNumbers[0]}₹
                    </Text>
                  )}
                </Button>
              </Flex>
            </SlideFade>
          </GridItem>
        </Grid>

        {showPlacedMessage && (
          <SlideFade in={true}>
            <Box
              bg={colorMode === 'light' ? 'white' : 'gray.800'}
              color={colorMode === 'light' ? 'black' : 'white'}
              p="4"
              borderRadius="md"
              boxShadow="md"
              mb="4"
              textAlign="center"
              position="relative"
            >
              <Box position="absolute" top="5px" right="5px" onClick={() => setShowPlacedMessage(false)} cursor="pointer">
                <FaCheckCircle size={20} color="green" />
              </Box>
              <Text mb="2" fontSize="lg">
                Bet placed successfully!
              </Text>
              <Text mb="2" fontSize="md">
                Selected Numbers: {Object.keys(selectedNumbers).join(', ')}
              </Text>
              <Text mb="2" fontSize="md">
                Total Bet Amount: {Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0)}₹
              </Text>
            </Box>
          </SlideFade>
        )}

        {error && (
          <Alert status="error" mt={4} color="black">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {loading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner size="lg" />
          </Flex>
        )}
        <Box mb="4" textAlign="center" width={{ base: '100%', md: '80%' }}>
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Input
              type="number"
              value={betAmount}
              onChange={handleBetAmountChange}
              placeholder="Enter amount"
              w={{ base: '100%', md: '50%' }}
              mb={{ base: '2', md: '0' }}
              mr={{ base: '0', md: '5' }}
              borderRadius={'40px'}
            />
            <Button
              onClick={placeBetConfirmation}
              colorScheme="blue"
              borderRadius={'50px'}
              bg={'red.600'}
              size="lg"
              color={'black'}
              mt={{ base: '4', md: '0' }}
              fontWeight={'bold'}
              w={{ base: '100%', md: 'auto' }}
              _hover={{ transform: 'scale(1.1)' }}
              isDisabled={!betAmount || mustSpin}
            >
              Place Bet
            </Button>
            <Button
              onClick={resetGame}
              colorScheme="blue"
              variant="outline"
              isDisabled={!betAmount || mustSpin}
              leftIcon={<Icon as={BiReset} />}
              ml={{ base: '0', md: '2' }}
              mt={{ base: '2', md: '0' }}
              borderRadius={'50px'}
              size="lg"
              w={{ base: '100%', md: 'auto' }}
              fontWeight={'bold'}
              _hover={{ transform: 'scale(1.1)' }}
            >
              Reset
            </Button>
          </Flex>
        </Box>
        <Box
          width={{ base: '100%', md: '80%', lg: '70%' }} // Adjust width for different screen sizes
          mx="auto" // Center the box horizontally
          p={4} // Add padding around the box
        >
          <Box
            bg="red.300"
            color="black"
            p={4}
            width="100%"
            borderRadius="md"
            boxShadow="md"
            marginBottom="20px"
          >
            <Heading mb={2} fontSize="xl" fontWeight="bold">
              History:
            </Heading>
            {gameHistory.map((entry, index) => (
              <Box
                key={index}
                mb={4}
                p={4}
                borderWidth={1}
                borderRadius="md"
                boxShadow="md"
                bg="red.100"
                _hover={{ shadow: 'lg' }}
              >
                <Flex justifyContent="space-between" alignItems="center" mb={2}>
                  <Text fontSize="lg">
                    Bet: {JSON.stringify(entry.bet)} {/* Use JSON.stringify or access a specific property */}
                  </Text>
                </Flex>
                <Text fontSize="md" mb={2}>
                  Result: {entry.result}
                </Text>
              </Box>
            ))}

          </Box>

          <Box
            bg="red.300"
            color="black"
            p={4}
            width="100%"
            borderRadius="md"
            boxShadow="md"
          >
            <Heading mb={2} fontSize="xl" fontWeight="bold">
              Game Rules:
            </Heading>
            <Text fontSize="md">1. Bet on numbers 0 to 9 with different amounts.</Text>
            <Text fontSize="md">2. Wheel will start automatically; and it stops after 10 seconds.</Text>
            <Text fontSize="md">3. You win if the wheel lands on your chosen number.</Text>
            <Text fontSize="md">4. Bets can't be changed once the wheel spins.</Text>
            <Text fontSize="md">5. Payouts depend on your bet amount.</Text>
            <Text fontSize="md">6. The game is fair, as it uses a random number generator to ensure fair game for everyone</Text>
            <Box
              p={4}
              borderRadius={'md'}
              mt={6}
            >
              <Heading
                as="h3"
                size={['sm', 'md']}
                textAlign="center"
                color="red.600"
              >
                Caution Note
              </Heading>
              <Text
                fontSize={['sm', 'md']}
                color={'red.700'}
                mt={4}
              >
                <b>Important:</b> Avoid navigating away or refreshing this page while placing bets to prevent errors or unregistered bets. We are not liable for issues caused by page refreshes or navigation; finalize all bets before leaving
              </Text>
            </Box>
          </Box>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} size={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalOverlay />
          <ModalContent borderRadius="xl" boxShadow="dark-lg" bg="gray.800">
            <ModalHeader textAlign="center" fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" color="yellow.400">Confirm Bet</ModalHeader>
            <ModalCloseButton color="yellow.400" />
            <ModalBody>
              <Heading size="md" mb={{ base: 2, md: 4 }} textAlign="center" color="gray.200">Bet Details</Heading>
              <Box mb="4">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Total Amount:</strong>
                  <Text as="span" ml="2" color="red.400" bg="red.900" fontWeight="bold" border="1px" borderColor="red.400" p="1" borderRadius="md">
                    ₹{Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0)}
                  </Text>
                </Text>
              </Box>
              <Box mb="4">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Selected Numbers:</strong>
                  <Text as="span" ml="2" color="blue.400" bg="blue.900" fontWeight="bold" border="1px" borderColor="blue.400" p="1" borderRadius="md">
                    {Object.entries(selectedNumbers).map(([number, amount], index) => (
                      <span key={number}>
                        {index > 0 && ', '}
                        {number}: {amount}
                      </span>
                    ))}
                  </Text>

                </Text>
              </Box>
              <Box mb="2">
                <Text fontSize={{ base: "sm", md: "md" }} color="gray.300">
                  <strong>Wallet Balance After Deduction:</strong>
                  <Text as="span" ml="2" color="purple.400" bg="purple.900" fontWeight="bold" border="1px" borderColor="purple.400" p="1" borderRadius="md">
                    ₹{walletBalance - Object.values(selectedNumbers).reduce((acc, cur) => acc + parseInt(cur), 0)}
                  </Text>
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button colorScheme="yellow" mr={3} onClick={placeBet} size={{ base: "md", md: "lg" }} borderRadius="full" boxShadow="lg" bg="yellow.600" _hover={{ bg: "yellow.500" }}>
                Yes, Roll!
              </Button>
              <Button onClick={onClose} size={{ base: "md", md: "lg" }} borderRadius="full" boxShadow="lg" color="gray.300" bg="gray.700" _hover={{ bg: "gray.600" }}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default SingleNumber;