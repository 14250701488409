import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Heading,
  Spinner,
  Text,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Icon,
  Td,
  IconButton,
  Tooltip,
  Collapse,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { MdErrorOutline, MdScreenRotation } from 'react-icons/md';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

const WalletTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLandscape, setIsLandscape] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;
      fetchTransactions(username);
    } else {
      setError('Token not found');
    }
  }, []);

  const fetchTransactions = async (username) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://newproject3-0-3stp.onrender.com/account/${username}/history`);
      const allTransactions = response.data.filter((transaction) => transaction.type !== 'BetWithdrawal');
      setTransactions(allTransactions);
      setLoading(false);
    } catch (error) {
      setError('No Transactions Found');
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const toggleTransactionDetails = (index) => {
    if (isMobile) {
      setSelectedTransaction(index);
      setIsModalOpen(true);
    } else {
      setSelectedTransaction(selectedTransaction === index ? null : index);
    }
  };

  const calculateBalance = (index) => {
    let balance = 0;

    for (let i = 0; i <= index; i++) {
      const transaction = transactions[i];

      if (!transaction || typeof transaction.amount !== 'number') {
        continue; // Skip invalid transactions
      }

      switch (transaction.type) {
        case 'Deposit':
        case 'Win':
          balance += transaction.amount;
          break;
        case 'Withdrawal':
          balance -= transaction.amount;
          break;
        case 'Bet':
          balance -= transaction.betAmount;
          break;
        default:
          break;
      }
    }

    return balance;
  };


  const handleOrientationToggle = async () => {
    if (window.screen.orientation) {
      try {
        if (window.screen.orientation.lock) {
          if (document.fullscreenEnabled && !document.fullscreenElement) {
            await document.documentElement.requestFullscreen();
          }
          const newOrientation = isLandscape ? 'portrait' : 'landscape';
          await window.screen.orientation.lock(newOrientation);
          setIsLandscape(!isLandscape);
          console.log(`Orientation locked to ${newOrientation}`);
        } else {
          console.warn('Screen orientation lock is not supported on this device.');
        }
      } catch (error) {
        console.error('Failed to lock orientation', error);
      }
    } else {
      console.warn('Screen orientation API is not supported in this browser.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTransaction(null);
  };

  return (
    <Box p={4}>
      <Heading as="h2" size="lg" mb={4}>
        Wallet Transactions
      </Heading>
      <Flex justify="flex-start" align="center" mb={4}>
        {isMobile && (
          <IconButton
            aria-label="Switch orientation"
            icon={<MdScreenRotation />}
            onClick={handleOrientationToggle}
            variant="outline"
            colorScheme="teal"
            size="sm"
            mr={0}
          />
        )}
      </Flex>

      {loading ? (
        <Flex justify="center" align="center" h="300px">
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
        <>
          {transactions.length > 0 ? (
            <Box overflowX="auto">
              <Table size={isMobile ? 'sm' : 'md'}>
                <Thead bg="green.200">
                  <Tr>
                    <Th>Date</Th>
                    <Th>Type</Th>
                    <Th>Debit</Th>
                    <Th>Credit</Th>
                    <Th>Status</Th>
                    <Th>Balance</Th>

                    <Th>Bet</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transactions.map((transaction, index) => {
                    const balance = calculateBalance(index);


                    return (
                      <React.Fragment key={index}>
                        <Tr>
                          <Td>
                            <Tooltip label={formatDate(transaction.createdAt)} aria-label="Date" placement="top">
                              <Text>{formatDate(transaction.createdAt)}</Text>
                            </Tooltip>
                          </Td>
                          <Td>{transaction.type}</Td>
                          <Td>
                            {transaction.type === 'Withdrawal' || transaction.type === 'Bet' ? (
                              <Text color="red.500">- ₹{transaction.type === 'Withdrawal' ? transaction.amount : transaction.betAmount}</Text>
                            ) : (
                              ''
                            )}
                          </Td>
                          <Td>
                            {transaction.type === 'Deposit' || transaction.type === 'Win' ? (
                              <Text color="green.500">+ ₹{transaction.amount}</Text>
                            ) : (
                              ''
                            )}
                          </Td>
                          <Td>{transaction.status}</Td>
                          <Td>
                            <Text
                              fontWeight="bold"
                              color="green"
                              rounded="md"
                              textAlign="center"
                              borderRadius={'20px'}
                              width="fit-content"
                            >
                              ₹{balance}
                            </Text>
                          </Td>

                          <Td>
                            {transaction.type === 'Bet' && (
                              <IconButton
                                aria-label={selectedTransaction === index ? 'Hide details' : 'View details'}
                                icon={selectedTransaction === index ? <FaArrowLeft /> : <FaArrowRight />}
                                onClick={() => toggleTransactionDetails(index)}
                                variant="outline"
                                colorScheme="teal"
                                size="sm"
                              />
                            )}
                          </Td>
                          {!isMobile && (
                            <Td>
                              <Collapse in={selectedTransaction === index}>
                                <Box
                                  p="2"
                                  mb="3"
                                  color="black"
                                  rounded="md"
                                  shadow="md"
                                  bg="blue.100"
                                  width={{ base: '100%', sm: '95%', md: '80%' }}
                                  mx="auto"
                                >
                                  <VStack align="start" spacing="1">
                                    <TransactionDetails transaction={transaction} />
                                  </VStack>
                                </Box>
                              </Collapse>
                            </Td>
                          )}
                        </Tr>
                      </React.Fragment>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Flex justify="center" align="center" h="300px">
               {/* Error and Orientation Warning */}
      {error && (
        <Flex justify="center" align="center" h="300px">
          <VStack spacing={4}>
            <Icon as={MdErrorOutline} boxSize={10} color="red.500" />
            <Text color="red.500" fontWeight="bold">{error}</Text>
          </VStack>
        </Flex>
      )}
            </Flex>
          )}
        </>
      )}

      {/* Mobile Modal for transaction details */}
      {isMobile && selectedTransaction !== null && (
        <Modal isOpen={isModalOpen} onClose={closeModal} size="md">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Transaction Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedTransaction !== null && (
                <TransactionDetails transaction={transactions[selectedTransaction]} />
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

     
    </Box>
  );
};

const TransactionDetails = ({ transaction }) => {
  return (
    <Box>
      <Text fontWeight="bold">GameName:</Text>
      <Text>{transaction.gameName}</Text>
      <Text fontWeight="bold">Transaction Type:</Text>
      <Text>{transaction.type}</Text>
      <Text fontWeight="bold">Amount:</Text>
      <Text>{transaction.type === 'Bet' ? transaction.betAmount : transaction.amount}</Text>
      <Text fontWeight="bold">Status:</Text>
      <Text>{transaction.status}</Text>
      <Text fontWeight="bold">Result:</Text>
      <Text>{transaction.betResult}</Text>
      <Text fontWeight="bold">Win:</Text>
      <Text>{transaction.winningAmount}</Text>
    </Box>
  );
};

export default WalletTransactions;
