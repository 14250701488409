import { useCallback } from 'react';
import {jwtDecode} from 'jwt-decode';
import useWalletBalance from './useWalletBalance';

const useWalletActions = () => {
  const { setWalletBalance, fetchWalletBalance } = useWalletBalance();

  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.username;
    }
    return null;
  };

  const withdrawFromWallet = useCallback(async (amount, isBetRelated = false) => {
    const token = localStorage.getItem('token');
    const username = getUsernameFromToken();
    if (!token || !username) return;

    // Optimistically update the balance
    setWalletBalance(prevBalance => prevBalance - amount);

    const makeRequest = async (retry = 3) => {
      try {
        const response = await fetch('https://newproject3-0-3stp.onrender.com/account/withdrawal', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username, amount, isBetRelated }),
        });

        if (response.ok) {
          const data = await response.json();
          setWalletBalance(data.balance);
        } else {
          throw new Error('Failed to withdraw from wallet');
        }
      } catch (error) {
        console.error('Error withdrawing from wallet:', error.message);
        if (retry > 0) {
          makeRequest(retry - 1);
        } else {
          // Revert optimistic update on failure
          setWalletBalance(prevBalance => prevBalance + amount);
        }
      } finally {
        fetchWalletBalance();
      }
    };

    makeRequest();
  }, [fetchWalletBalance, setWalletBalance]);

  const depositToWallet = useCallback(async (amount, isBetRelated = false) => {
    const token = localStorage.getItem('token');
    const username = getUsernameFromToken();
    if (!token || !username) return;

    // Optimistically update the balance
    setWalletBalance(prevBalance => prevBalance + amount);

    const makeRequest = async (retry = 3) => {
      try {
        const response = await fetch('https://newproject3-0-3stp.onrender.com/account/deposit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username, amount, isBetRelated }),
        });

        if (response.ok) {
          const data = await response.json();
          setWalletBalance(data.balance);
        } else {
          throw new Error('Failed to deposit to wallet');
        }
      } catch (error) {
        console.error('Error depositing to wallet:', error.message);
        if (retry > 0) {
          makeRequest(retry - 1);
        } else {
          // Revert optimistic update on failure
          setWalletBalance(prevBalance => prevBalance - amount);
        }
      } finally {
        fetchWalletBalance();
      }
    };

    makeRequest();
  }, [fetchWalletBalance, setWalletBalance]);

  return { withdrawFromWallet, depositToWallet, fetchWalletBalance };
};

export default useWalletActions;
