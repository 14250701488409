import React, { useState, useEffect } from 'react';
import '../Style/DiceGame.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { keyframes } from "@emotion/react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Center,
  VStack,
  Input,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { FaArrowsAltV } from "react-icons/fa";
import diceRollSound from './dice-142528.mp3'; // Import your sound file
import { Howl, Howler } from 'howler'; // Ensure you import howler for sound effects
import { jwtDecode } from 'jwt-decode';
import useWalletActions from './hook/useWalletActions'; // Import your custom hooks
import useWalletBalance from './hook/useWalletBalance'; // Import your custom hooks
// Replace the following library.add line if needed
library.add(fas);
// Pulsing animation
const pulse = keyframes`
  0% {
    transform: scale(1);
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  }
  50% {
    transform: scale(1.05);
    text-shadow: 0 0 15px rgba(0, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    text-shadow: 0 0 5px rgba(0, 255, 255, 0.7);
  }
`;

// Styles for the timer text with pulsing animation
const timerStyles = {
  fontSize: "5xl",
  fontWeight: "bold",
  color: " black",
  textAlign: "center",
  animation: `${pulse} 1.5s ease-in-out infinite`,
};
const DiceHighLowGame = () => {
  const { walletBalance, fetchWalletBalance } = useWalletBalance();
  const { withdrawFromWallet, depositToWallet } = useWalletActions();
  const [die1, setDie1] = useState('one');
  const [die2, setDie2] = useState('one');
  const [rolling, setRolling] = useState(false);
  const [betAmount, setBetAmount] = useState('');
  const [result, setResult] = useState('');
  const [username, setUsername] = useState('');
  const [history, setHistory] = useState([]);
  const [betType, setBetType] = useState('');
  const [confirmBet, setConfirmBet] = useState(false);
  const initialTime = 10; // Set the initial time here
  const [timeLeft, setTimeLeft] = useState(initialTime);
  const [soundMuted, setSoundMuted] = useState(false);
  const toast = useToast();
  const sides = ['one', 'two', 'three', 'four', 'five', 'six'];

  // Define sound effect
  const sound = new Howl({
    src: [diceRollSound],
  });

  useEffect(() => {
    Howler.volume(soundMuted ? 0 : 1);
    return () => {
      sound.unload();
    };
  }, [soundMuted]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (rolling) {
        event.preventDefault();
        event.returnValue = 'You have an ongoing game. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rolling]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const usernameFromToken = decodedToken.username;
      setUsername(usernameFromToken);

      // Trigger initial fetch and polling via the hook
      fetchWalletBalance(usernameFromToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWalletBalance]);

  const updateBackendBetResult = async (isWin, winningAmount, total, betType) => {
    const token = localStorage.getItem('token');
    const username = getUsernameFromToken();
    if (token) {
      try {
        const response = await fetch('https://newproject3-0-3stp.onrender.com/account/bet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username,
            betAmount: parseInt(betAmount),
            gameName: 'High|Low',
            category: 'Dice',
            betResult: isWin ? 'Win' : 'Loss',
            selectedNumbers: [total], // Use total instead of result
            winningAmount,
            betType: betType, // Send betType as Low, Neutral, or High based on the bet
          }),
        });
        if (response.ok) {
          if (isWin) {
            await depositToWallet(winningAmount, true);
          }
          setConfirmBet(false); // Close the modal after the bet is placed
        } else {
          const data = await response.json();
          toast({
            title: 'Error',
            description: data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error placing bet:', error.message);
      }
    }
  };

  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.username;
    }
    return '';
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  useEffect(() => {
    let countdown;

    if (rolling) {
      countdown = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(countdown);
            setRolling(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    // Cleanup interval on component unmount
    return () => clearInterval(countdown);
  }, [rolling]);

  const rollDice = async () => {
    if (rolling) return;

    setRolling(true);
    setConfirmBet(false); // Close the modal here
    setTimeLeft(initialTime); // Reset the timer

    await withdrawFromWallet(parseInt(betAmount), true); // Withdraw the bet amount

    // Timer for countdown
    const timerInterval = 1000; // 1 second interval for real-time countdown
    let countdownTime = initialTime;
    const timer = setInterval(() => {
      countdownTime--;
      setTimeLeft(countdownTime);

      if (countdownTime <= 0) {
        clearInterval(timer);

        // Final dice roll after countdown completes
        const randomDie1 = sides[Math.floor(Math.random() * sides.length)];
        const randomDie2 = sides[Math.floor(Math.random() * sides.length)];
        setDie1(randomDie1);
        setDie2(randomDie2);

        const total = sides.indexOf(randomDie1) + sides.indexOf(randomDie2) + 2;
        sound.play();

        // Delay the result display to ensure dice roll animation completes
        setTimeout(() => {
          let isWin = false;
          let winningAmount = 0;

          if ((betType === 'Low' && total >= 2 && total <= 6) ||
            (betType === 'High' && total >= 8 && total <= 12) ||
            (betType === 'Neutral' && total === 7)) {
            isWin = true;
            winningAmount = parseInt(betAmount) * 2;
            setResult(`You won ₹${winningAmount}`);

            // Show winning toast
            toast({
              title: 'Congratulations!',
              description: `You won ₹${winningAmount}!`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          } else {
            setResult(`You lost ₹${betAmount}`);

            // Show losing toast
            toast({
              title: 'Sorry!',
              description: `You lost ₹${betAmount}. Better luck next time!`,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }

          updateBackendBetResult(isWin, winningAmount, total, betType); // Update to send betType
          setHistory([
            ...history,
            {
              win: isWin,
              amount: isWin ? winningAmount : parseInt(betAmount),
            },
          ]);
          setRolling(false);
        }, 500); // Adjust the delay as needed for seamless transition
      }
    }, timerInterval); // Real-time countdown

    // Dice rolling updates with increased frequency
    const rollingInterval = 200; // Faster update for dice rolling
    const rollDiceInterval = setInterval(() => {
      if (countdownTime <= 0) {
        clearInterval(rollDiceInterval);
        return;
      }

      const randomDie1 = sides[Math.floor(Math.random() * sides.length)];
      const randomDie2 = sides[Math.floor(Math.random() * sides.length)];
      setDie1(randomDie1);
      setDie2(randomDie2);
    }, rollingInterval);
  };

  // Function to display result and remove after 3 seconds
  useEffect(() => {
    if (result) {
      const timer = setTimeout(() => {
        setResult('');
      }, 3000); // Remove result after 3 seconds
      return () => clearTimeout(timer); // Clear timeout on component unmount or when result changes
    }
  }, [result]);

  const handleBet = async (type) => {
    if (!betAmount) {
      toast({
        title: 'Error',
        description: 'Please enter a bet amount.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isNaN(betAmount) || betAmount <= 0) {
      toast({
        title: 'Error',
        description: 'Please enter a valid bet amount.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (betAmount > walletBalance) {
      toast({
        title: 'Error',
        description: 'Insufficient balance.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setConfirmBet(true);
    setBetType(type); // Set betType based on button clicked
  };

  const toggleSound = () => {
    setSoundMuted(!soundMuted);
  };

  return (
    <Flex justifyContent="center" alignItems="center" minHeight="100vh" backgroundColor="#8E7AB5" color="#FFF">
      <Box p="4" bg="transparent" w="90%" maxW="800px" borderRadius="md">
        <Heading
          as="h1"
          mb="4"
          textAlign="center"
          color="#000000"
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight="bold"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          High <FaArrowsAltV style={{ margin: '0 10px' }} /> Low
        </Heading>
        <Box textAlign="center" mb="4">
          <Flex justifyContent="center" alignItems="center" mb="4">
            <Box className={`Die2 ${rolling && 'Die-shaking'}`}>
              <FontAwesomeIcon icon={['fas', `fa-dice-${die1}`]} size="lg" />
            </Box>
            <Box className={`Die2 ${rolling && 'Die-shaking'}`}>
              <FontAwesomeIcon icon={['fas', `fa-dice-${die2}`]} size="lg" />
            </Box>
          </Flex>
          <Box textAlign="center" mt={'-30px'} mb="4">
            <Button
              size="md"
              color={'black'}
              colorScheme="cyan"
              variant='ghost'
              onClick={toggleSound} // Add this line to handle the button click
              leftIcon={soundMuted ? <FontAwesomeIcon icon={['fas', 'volume-mute']} /> : <FontAwesomeIcon icon={['fas', 'volume-up']} />}
            />

          </Box>
          <Center mb={8}>
            {rolling && (
              <Text sx={timerStyles}>
                {timeLeft}
              </Text>
            )}
          </Center>
          <Text fontSize="2xl" fontWeight="bold" color={result.includes('won') ? 'green.800' : 'red.800'}>
            {result}
          </Text>

        </Box>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          mb="4"
          p={{ base: '2', md: '4' }}
        >
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Input
              type="number"
              value={betAmount}
              onChange={(e) => setBetAmount(e.target.value)}
              placeholder="Enter amount"
              borderColor="black"
              _placeholder={{ color: "black" }}
              style={{ padding: '8px', border: "4px solid black", color: 'white' }}
              minWidth={{ base: '100%', md: '200px' }}
              borderRadius={'40px'}
              mb={{ base: '2', md: '0' }}
            />
            <Tooltip label="2, 3, 4, 5, 6" aria-label="Bet Low numbers" placement="top" hasArrow>

              <Button
                mt={{ base: '2', md: '0' }}
                ml={{ base: '0', md: '2' }}
                bgGradient="linear(to-r, yellow.400, orange.300)"
                color="black"
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'lg' }}
                isDisabled={!betAmount || rolling}
                onClick={() => handleBet('Low')} // Pass 'Low' for Bet Low button
                _hover={{ bgGradient: "linear(to-r, yellow.500, orange.400)" }}
                width={{ base: '100%', md: 'auto' }}
                borderRadius="40px"
                boxShadow="0 8px 12px rgba(0, 0, 0, 0.2)"
                p={{ base: 4, md: 6 }}
                textAlign="center"
              >
                Bet Low
              </Button>
            </Tooltip>
            <Tooltip label="7" aria-label="Neutral number" placement="top" hasArrow >
              <Button
                mt={{ base: '2', md: '0' }}
                ml={{ base: '0', md: '2' }}
                bgGradient="linear(to-r, teal.400, cyan.300)"
                color="black"
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'lg' }}
                isDisabled={!betAmount || rolling}
                onClick={() => handleBet('Neutral')} // Pass 'Neutral' for 7 Neutral button
                _hover={{ bgGradient: "linear(to-r, teal.500, cyan.400)" }}
                width={{ base: '100%', md: 'auto' }}
                borderRadius="40px"
                boxShadow="0 8px 12px rgba(0, 0, 0, 0.2)"
                p={{ base: 4, md: 6 }}
                textAlign="center"
              >
                7 Neutral
              </Button>
            </Tooltip>
            <Tooltip label="8, 9, 10, 11, 12" aria-label="Bet High numbers" placement="top" hasArrow>
              <Button
                mt={{ base: '2', md: '0' }}
                ml={{ base: '0', md: '2' }}
                bgGradient="linear(to-r, green.400, green.300)"
                color="black"
                fontWeight="bold"
                fontSize={{ base: 'md', md: 'lg' }}
                isDisabled={!betAmount || rolling}
                onClick={() => handleBet('High')} // Pass 'High' for Bet High button
                _hover={{ bgGradient: "linear(to-r, green.500, green.400)" }}
                width={{ base: '100%', md: 'auto' }}
                borderRadius="40px"
                boxShadow="0 8px 12px rgba(0, 0, 0, 0.2)"
                p={{ base: 4, md: 6 }}
                textAlign="center"
              >
                Bet High
              </Button>
            </Tooltip>
          </Box>
        </Flex>
        <Box bg="black" p="4" borderRadius="md" mb="4">
          <Heading mb="2" fontSize="lg" color={'grey'} fontWeight="bold">
            History:
          </Heading>
          {history.map((item, index) => (
            <Flex key={index} justifyContent="space-between" alignItems="center">
              <Text fontSize="md" color={'grey'}>{item.win ? 'Won' : 'Lost'}</Text>
              <Text fontSize="md" color={'grey'}>{item.amount}₹</Text>
            </Flex>
          ))}
        </Box>
        <Box p="4" bg="black" borderRadius="md" mb="4">
          <Heading mb="2" fontSize="lg" color={'grey'} fontWeight="bold">
            Rules:
          </Heading>
          <VStack align="start" color={'grey'} spacing="2">
            <Text>Choose between "Bet High" (sums 8 to 12) or "Bet Low" (sums 2 to 6).</Text>
            <Text>You win if the dice sum is between 8 and 12.</Text>
            <Text>You win if the dice sum is between 2 and 6.</Text>
            <Text>A sum of 7 means you lose, no matter what you bet.</Text>
            <Text>You can only place your bet once per roll and cannot change it after the dice start rolling.</Text>
            <Text>Winning or losing is based on the dice sum compared to your bet choice.</Text>
            <Text>The game uses a random number generator to ensure fairness for all players.</Text>
            <Box
              bg={'black'}
              p={4}
              borderRadius={'md'}

              mt={6}
            >
              <Heading
                as="h3"
                size={['sm', 'md']}
                textAlign="center"
                color="#FF6F6F"
              >
                Caution Note
              </Heading>
              <Text
                fontSize={['sm', 'md']}
                color={'#FF4D4D'}
                mt={4}
              >
                <b>Important:</b> Avoid navigating away or refreshing this page while placing bets to prevent errors or unregistered bets. We are not liable for issues caused by page refreshes or navigation; finalize all bets before leaving
              </Text>
            </Box>
          </VStack>
        </Box>
        <Modal isOpen={confirmBet} onClose={() => setConfirmBet(false)} size={{ base: "xs", sm: "sm", md: "md", lg: "lg" }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Bet</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to place a bet of {betAmount}₹ and bet {result}?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={rollDice}>
                Yes, Roll!
              </Button>
              <Button onClick={() => setConfirmBet(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
};

export default DiceHighLowGame;
