import React from 'react';
import { Link } from 'react-router-dom';
import { FaDiceTwo, FaDice } from 'react-icons/fa';
import { RxColorWheel } from 'react-icons/rx';
import { GiOppositeHearts } from 'react-icons/gi';
import { FaUpDown } from 'react-icons/fa6';
import {
  Box,
  Heading,
  Text,
  Center,
  keyframes,
  Grid,
  LinkBox,
  LinkOverlay,
  useColorModeValue
} from '@chakra-ui/react';

const textAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const hoverAnimation = keyframes`
  0%, 100% { transform: rotate(0); }
  50% { transform: rotate(10deg); }
`;

const pulseAnimation = keyframes`
   0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); border-radius: 50%; }
  70% { box-shadow: 0 0 0 20px rgba(0, 0, 0, 0); border-radius: 0%; }
  100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); border-radius: 50%; }
`;

const headingGradient = {
  bgGradient: 'linear(to-r, #E49BFF, black, black)',
  bgClip: 'text',
};

const cardsData = [
  { id: 1, icon: <RxColorWheel />, color: 'linear(to-r, #C40C0C,black)', title: 'Spin & Win', link: '/single', iconColor: '#FFD700', textColor: '#FFD700' },
  { id: 2, icon: <FaDiceTwo />, color: 'linear(to-r, #973131,black)', title: 'Duo Digits', link: '/double', iconColor: '#BB9AB1', textColor: '#BB9AB1' },
  { id: 3, icon: <GiOppositeHearts />, color: 'linear(to-r, #FF6191, black)', title: 'Colour Shuffle', link: '/colours', iconColor: 'pink', textColor: 'pink' },
  { id: 4, icon: <FaDice />, color: 'linear(to-r, #BB9AB1,black)', title: 'Duo Dice', link: '/Dicerolling', iconColor: 'orange', textColor: 'orange' },
  { id: 5, icon: <FaUpDown />, color: 'linear(to-r, #88D66C, black)', title: 'HighLow', link: '/poker', iconColor: '#32CD32', textColor: '#32CD32' },
];

const Card = ({ icon, color, title, link, iconColor, textColor }) => (
  <LinkBox
    as="article"
    w="100%"
    h="200px"
    borderRadius="15px"
    overflow="hidden"
    boxShadow="lg"
    transition="transform 0.3s ease, box-shadow 0.3s ease"
    _hover={{ transform: 'translateY(-10px)', boxShadow: 'xl' }}
    bgGradient={color}
    textAlign="center"
  >
    <Box
      w="full"
      h="full"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      color={textColor}
    >
      <Box as="span" fontSize="2.5em" animation={`${pulseAnimation} 2s infinite`} color={iconColor}>
        {icon}
      </Box>
      <Text fontSize="1.2em" mt={2} fontWeight="bold">
        {title}
      </Text>
    </Box>
    <LinkOverlay as={Link} to={link} />
  </LinkBox>
);

const CardList = () => {
  return (
    <Box textAlign="center" bg={useColorModeValue('#201E43', '#1A202C')} py={10} minH="90vh">
      <Center mb={8}>
        <Heading
          fontSize={{ base: '30px', md: '50px' }}
          animation={`${textAnimation} 3s ease-in-out infinite`}
          {...headingGradient}
        >
          Game{' '}
          <Text as="span" ml="2px" color="pink.500" bgGradient="linear(to-r, #E49BFF, black)" bgClip="text">
            Zone
          </Text>
        </Heading>
      </Center>
      <Grid
        templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)' }}
        gap={6}
        px={4}
      >
        {cardsData.map(card => (
          <Card
            key={card.id}
            icon={card.icon}
            color={card.color}
            title={card.title}
            link={card.link}
            iconColor={card.iconColor}
            textColor={card.textColor}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default CardList;
