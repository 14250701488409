import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Spinner,
  Text,
  Flex,
  Badge,
  useToast,
} from '@chakra-ui/react';
import { EditIcon, AddIcon, CheckIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    ifscCode: '',
    bankName: '',
    branch: '',
    name: '',
    isValidIFSC: false,
    loadingBasicDetails: true,
    showForm: false,
    showEditButton: false,
    error: '',
  });

  const [isEditing, setIsEditing] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchBasicBankDetails = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;
          const response = await axios.get(`https://newproject3-0-3stp.onrender.com/api/${username}/bank-details`);
          if (response.data) {
            const savedBankDetails = response.data;
            if (savedBankDetails) {
              setBankDetails({
                ...savedBankDetails,
                showForm: false,
                showEditButton: true,
                loadingBasicDetails: false,
                error: '',
              });
              setIsEditing(true);
            } else {
              setBankDetails({
                ...bankDetails,
                showForm: true,
                showEditButton: false,
                loadingBasicDetails: false,
                error: '',
              });
            }
          } else {
            setBankDetails({
              ...bankDetails,
              loadingBasicDetails: false,
              error: 'No bank details found.please add your bank details',
            });
          }
        } catch (error) {
          // console.error('Error retrieving bank details:', error);
          setBankDetails({ ...bankDetails, loadingBasicDetails: false, error: 'Error retrieving bank details. Please try again.' });
        }
      } else {
        setBankDetails({ ...bankDetails, loadingBasicDetails: false, error: 'Token not found' });
      }
    };

    fetchBasicBankDetails();
  }, []);

  const handleIFSCVerification = (ifscCode) => {
    setBankDetails({ ...bankDetails, loading: true });

    axios.get(`https://ifsc.razorpay.com/${ifscCode}`)
      .then(response => {
        const { BANK, BRANCH } = response.data;
        setBankDetails({
          ...bankDetails,
          bankName: BANK,
          branch: BRANCH,
          loading: false,
          isValidIFSC: true,
        });
        toast({
          title: "IFSC Code Verified",
          description: `Bank: ${BANK}, Branch: ${BRANCH}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(error => {
        console.error('Error verifying IFSC:', error);
        setBankDetails({ ...bankDetails, loading: false, isValidIFSC: false });
        toast({
          title: "Error",
          description: "Invalid IFSC Code",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleEditButtonClick = () => {
    setBankDetails({ ...bankDetails, showForm: true, showEditButton: false });
  };

  const handlePostBankDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const response = await axios.post(
        `https://newproject3-0-3stp.onrender.com/api/${username}/bank-details`,
        {
          accountNumber: bankDetails.accountNumber,
          ifscCode: bankDetails.ifscCode,
          bankName: bankDetails.bankName,
          branch: bankDetails.branch,
          name: bankDetails.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const savedBankDetails = response.data;
      setBankDetails({
        ...bankDetails,
        ...savedBankDetails,
        showForm: false,
        showEditButton: true,
        error: '',
      });
      setIsEditing(true);

      toast({
        title: "Success",
        description: "Bank details saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving bank details:', error);
      setBankDetails({ ...bankDetails, error: 'Error saving bank details. Please try again.' });
      toast({
        title: "Error",
        description: "Failed to save bank details",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handlePatchBankDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const response = await axios.patch(
        `https://newproject3-0-3stp.onrender.com/api/${username}/bank-details`,
        {
          accountNumber: bankDetails.accountNumber,
          ifscCode: bankDetails.ifscCode,
          bankName: bankDetails.bankName,
          branch: bankDetails.branch,
          name: bankDetails.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const savedBankDetails = response.data;
      setBankDetails({
        ...bankDetails,
        ...savedBankDetails,
        showForm: false,
        showEditButton: true,
        error: '',
      });

      toast({
        title: "Success",
        description: "Bank details updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating bank details:', error);
      setBankDetails({ ...bankDetails, error: 'Error updating bank details. Please try again.' });
      toast({
        title: "Error",
        description: "Failed to update bank details",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (bankDetails.loadingBasicDetails) {
    return (
      <Box maxW="600px" mx="auto" p="6" bg="gray.50" borderRadius="md" boxShadow="lg">
        <Spinner size="xl" color="teal.500" />
      </Box>
    );
  }

  return (
    <Box maxW="600px" mx="auto"  mt={{ base: '0', md: '20px', lg: '50px' }} p="6" bg="gray.50" borderRadius="md" boxShadow="lg">
      <Heading as="h2" mb="6" textAlign="center" color="teal.500"  fontSize={{ base: '1.3em', md: '1.5em', lg: '2em' }}>Bank Details</Heading>
      {bankDetails.showForm ? (
        <Box as="form" onSubmit={isEditing ? handlePatchBankDetails : handlePostBankDetails}>
          <FormControl id="name" mb="4" isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" value={bankDetails.name} onChange={(e) => setBankDetails({ ...bankDetails, name: e.target.value })} />
          </FormControl>
          <FormControl id="accountNumber" mb="4" isRequired>
            <FormLabel>Account Number</FormLabel>
            <Input type="text" value={bankDetails.accountNumber} onChange={(e) => setBankDetails({ ...bankDetails, accountNumber: e.target.value })} />
          </FormControl>
          <FormControl id="ifscCode" mb="4" isRequired>
            <FormLabel>IFSC Code</FormLabel>
            <Flex>
              <Input
                type="text"
                value={bankDetails.ifscCode}
                onChange={(e) => setBankDetails({ ...bankDetails, ifscCode: e.target.value })}
              />
              <Button
                ml="2"
                onClick={() => handleIFSCVerification(bankDetails.ifscCode)}
                isLoading={bankDetails.loading}
                isDisabled={bankDetails.loading}
                aria-label="Verify IFSC Code"
                variant="outline"
                colorScheme="teal"
                color="teal.500"
                _hover={{ bg: 'teal.600', color: 'white' }}
                _active={{ bg: 'teal.700', color: 'white' }}
              >
                Verify IFSC
              </Button>
            </Flex>
          </FormControl>
          {bankDetails.isValidIFSC && (
            <>
              <FormControl id="bankName" mb="4" isRequired>
                <FormLabel>Bank Name</FormLabel>
                <Input type="text" value={bankDetails.bankName} readOnly />
              </FormControl>
              <FormControl id="branch" mb="4" isRequired>
                <FormLabel>Branch</FormLabel>
                <Input type="text" value={bankDetails.branch} readOnly />
              </FormControl>
            </>
          )}
          <Button type="submit" colorScheme="teal" color="teal.500" variant="outline" _hover={{ bg: 'teal.600', color: 'white' }}
            _active={{ bg: 'teal.700', color: 'white' }}>
            {isEditing ? 'Update Details' : 'Save Details'}
          </Button>
        </Box>
      ) : (
        <>
          {bankDetails.showEditButton && (
            <Button onClick={handleEditButtonClick}  fontSize={{ base: '15px', md: 'xl', lg: 'xl' }} colorScheme="teal" mb="4">
              <EditIcon mr="2" />
              
            </Button>
          )}
          {bankDetails.accountNumber ? (
            <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p="4" bg="white">
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Account Number:</Text>
                <Text>{bankDetails.accountNumber}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">IFSC Code:</Text>
                <Text>
                  {bankDetails.ifscCode}
                  {bankDetails.isValidIFSC && (
                    <Badge colorScheme="green" ml="2" variant="solid" borderRadius="full">
                      <CheckIcon boxSize={4} mr="1" /> Verified
                    </Badge>
                  )}
                </Text>

              </Flex>
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Bank Name:</Text>
                <Text>{bankDetails.bankName}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Branch:</Text>
                <Text>{bankDetails.branch}</Text>
              </Flex>
              <Flex justify="space-between" align="center">
                <Text fontWeight="bold">Name:</Text>
                <Text>{bankDetails.name}</Text>
              </Flex>
            </Box>
          ) : (
            <Box textAlign="center" p="4" bg="white" borderRadius="md" boxShadow="md">
              <Text color="gray.500" fontSize={'20px'} mb="4">No bank details found.please add your bank details</Text>
              <Button onClick={() => setBankDetails({ ...bankDetails, showForm: true })} colorScheme="teal">
                Add Bank Details
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BankDetails;
