import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Input,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRegListAlt, FaInfoCircle } from 'react-icons/fa';
import redSound from './colour/red-7991.mp3';
import greenSound from './colour/green-7987.mp3';
import blueSound from './colour/blue-7985.mp3';
import yellowSound from './colour/yellow-7993.mp3';
import purpleSound from './colour/purple-7990.mp3';
import orangeSound from './colour/orange-7988.mp3';
import pinkSound from './colour/pink-7989.mp3';
import {jwtDecode} from 'jwt-decode';

import useWalletBalance from './hook/useWalletBalance'; // Import the custom hook for wallet balance
import useWalletActions from './hook/useWalletActions'; // Import the custom hook for wallet actions

const ColorPredictionGame = () => {
  const colors = ['Red', 'Green', 'Blue', 'Yellow', 'Purple', 'Orange', 'Pink'];
  const [selectedColor, setSelectedColor] = useState('');
  const [betAmount, setBetAmount] = useState('');
  const [result, setResult] = useState('');
  const [message, setMessage] = useState('');
  const [gameStarted, setGameStarted] = useState(false);
  const [shuffledColor, setShuffledColor] = useState('');
  const [showAnimation, setShowAnimation] = useState(false);
  const [history, setHistory] = useState([]);
  const [timer, setTimer] = useState(0);
  const [showWinningColor, setShowWinningColor] = useState(false);
  const toast = useToast();

  // Use the custom hooks for wallet balance and actions
  const { walletBalance, fetchWalletBalance } = useWalletBalance();
  const { withdrawFromWallet, depositToWallet } = useWalletActions();

  const audioFiles = {
    Red: new Audio(redSound),
    Green: new Audio(greenSound),
    Blue: new Audio(blueSound),
    Yellow: new Audio(yellowSound),
    Purple: new Audio(purpleSound),
    Orange: new Audio(orangeSound),
    Pink: new Audio(pinkSound),
  };

  useEffect(() => {
    fetchWalletBalance(); // Fetch the wallet balance when the component mounts

    const handleBeforeUnload = (event) => {
      if (gameStarted) {
        event.preventDefault();
        event.returnValue = 'You have an ongoing game. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted]);

  useEffect(() => {
    let timerId;
    if (showAnimation) {
      setTimer(10);
      timerId = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(timerId);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerId);
  }, [showAnimation]);

  const MAX_PAYOUT = 100000; // Maximum payout limit

  const getWinProbability = (betAmount, history) => {
    const baseWinProbability = 3 / colors.length;
    let adjustedWinProbability = baseWinProbability * Math.min(1, 100 / betAmount);

    const lossStreak = history.reduce((streak, entry) => (entry.result !== selectedColor ? streak + 1 : 0), 0);
    adjustedWinProbability *= Math.pow(0.95, lossStreak);

    if (history.length < 5) {
      adjustedWinProbability = Math.min(0.1, adjustedWinProbability);
    }

    return adjustedWinProbability;
  };

  const getRandomColorWithHouseEdge = (betAmount, history) => {
    const winProbability = getWinProbability(betAmount, history);

    if (Math.random() < winProbability) {
      return selectedColor;
    }

    let randomColor;
    do {
      randomColor = colors[Math.floor(Math.random() * colors.length)];
    } while (randomColor === selectedColor);

    return randomColor;
  };

  const patternDetection = (history) => {
    const lossStreak = history.reduce((streak, entry) => (entry.result !== selectedColor ? streak + 1 : 0), 0);
    return lossStreak >= 3;
  };

  const getSmallWinBonus = () => {
    const bonuses = [10, 20, 30];
    return bonuses[Math.floor(Math.random() * bonuses.length)];
  };

  const shuffleColors = () => {
    setGameStarted(true);
    setShowAnimation(true);
    setShowWinningColor(false);

    setTimeout(() => {
      let randomColor;

      if (patternDetection(history)) {
        randomColor = getRandomColorWithHouseEdge(betAmount, history);
      } else {
        const randomIndex = Math.floor(Math.random() * colors.length);
        randomColor = colors[randomIndex];
      }

      setShuffledColor(randomColor);
      evaluateResult(randomColor);
      setShowAnimation(false);
      setShowWinningColor(true);
    }, 10000); // 10 seconds
  };

  const evaluateResult = (randomColor) => {
    let resultMessage = '';

    if (selectedColor === randomColor) {
      let payout = Math.min(betAmount * 2, MAX_PAYOUT); // Cap the payout
      resultMessage = `Congratulations! You won ₹${payout}`;
      setResult('win');
      updateBackendBetResult(true, payout);
    } else {
      if (Math.random() < 0.1) {
        const bonus = getSmallWinBonus();
        resultMessage = `Better luck next time! You received a bonus of ₹${bonus}.`;
        setResult('bonus');
        updateBackendBetResult(false, bonus);
      } else {
        resultMessage = `Better luck next time! You lost the bet.`;
        setResult('lose');
        updateBackendBetResult(false, 0);
      }
    }

    toast({
      title: selectedColor === randomColor ? 'Congratulations!' : 'Better luck next time!',
      description: resultMessage,
      status: selectedColor === randomColor ? 'success' : 'error',
      duration: 2000,
      isClosable: true,
    });

    playSound(randomColor);

    setHistory([
      ...history,
      {
        selectedColor,
        betAmount,
        result: randomColor,
        outcome: resultMessage,
      },
    ]);

    setGameStarted(false);
    fetchWalletBalance(); // Fetch the wallet balance after the bet is resolved
  };

  const playSound = (color) => {
    const audio = audioFiles[color];
    if (audio) {
      audio.play();
    }
  };

  const handleBet = async () => {
    if (!selectedColor || !betAmount) {
      setMessage('Please select a color and enter a bet amount.');
      return;
    }

    if (isNaN(betAmount) || betAmount <= 0) {
      setMessage('Please enter a valid bet amount.');
      return;
    }

    if (betAmount > walletBalance) {
      setMessage('Insufficient balance.');
      return;
    }

    setMessage('');
    withdrawFromWallet(betAmount, true); // Withdraw from wallet for betting
    shuffleColors();
  };

  const updateBackendBetResult = async (isWin, winningAmount) => {
    const token = localStorage.getItem('token');
    const username = getUsernameFromToken();
    if (token) {
      try {
        const response = await fetch('https://newproject3-0-3stp.onrender.com/account/bet', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username,
            betAmount: parseInt(betAmount),
            gameName: 'Colour Shuffle',
            category: 'Color',
            betResult: isWin ? 'Win' : 'Loss',
            selectedNumbers: [selectedColor],
            winningAmount,
          }),
        });
        if (response.ok) {
          if (isWin) {
            await depositToWallet(winningAmount, true);
          }
        } else {
          const data = await response.json();
          toast({
            title: 'Error',
            description: data.message || 'Failed to update bet result.',
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error updating bet result:', error);
        toast({
          title: 'Error',
          description: 'Failed to update bet result.',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      return decodedToken.username;
    }
    return '';
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setMessage('');
  };

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const swapBalls = (balls) => {
    const newBalls = [...balls];
    for (let i = 0; i < newBalls.length; i++) {
      const j = getRandomInt(newBalls.length);
      [newBalls[i], newBalls[j]] = [newBalls[j], newBalls[i]];
    }
    return newBalls;
  };

  const Ball = ({ color }) => {
    // Define background color based on the color prop
    let backgroundColor, borderColor;
    switch (color) {
      case 'Red':
        backgroundColor = '#ff6347'; // Tomato
        borderColor = '2px solid white';
        break;
      case 'Green':
        backgroundColor = '#2ecc71'; // Emerald
        borderColor = '2px solid white';
        break;
      case 'Blue':
        backgroundColor = '#3498db'; // Peter River
        borderColor = '2px solid white';
        break;
      case 'Yellow':
        backgroundColor = '#f1c40f'; // Sunflower
        borderColor = '2px solid white';
        break;
      case 'Purple':
        backgroundColor = '#9b59b6'; // Amethyst
        borderColor = '2px solid white';
        break;
      case 'Orange':
        backgroundColor = '#e67e22'; // Carrot
        borderColor = '2px solid white';
        break;
      case 'Pink':
        backgroundColor = '#E90074'; // Pink
        borderColor = '2px solid white';
        break;
      default:
        backgroundColor = '#333'; // Default background color
    }

    return (
      <motion.div
        key={color}
        style={{
          cursor: 'pointer',
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          position: 'relative',
          margin: '10px',
        }}
        onClick={() => handleColorSelect(color)}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            background: ` ${backgroundColor} `,
            border: ` ${borderColor} `,
            boxShadow: selectedColor === color ? '0 0 35px orange' : '0 4px 8px rgba(0, 0, 0, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#333',
          }}
        >
          {color}
        </div>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '86px',
            height: '86px',
            borderRadius: '50%',
            border: '9px dashed white',
          }}
        />
      </motion.div>
    );
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor="#1E0342"
      backgroundSize="cover"
      backgroundPosition="center"
      p="4"
    >
      <Box
        p={{ base: '4', md: '6' }}
        bg="transparent"
        borderRadius="lg"
        textAlign="center"
        maxWidth={{ base: '100%', md: '900px' }}
        width="100%"
        color="white"
      >
        <Heading as="h1" color="purple.400" mb={{ base: '4', md: '6' }}>
          Colour Shuffle
        </Heading>
        <Flex alignItems="center" justifyContent="center" flexWrap="wrap" position="relative">
          <AnimatePresence>
            {!showAnimation && colors.map((color) => (
              <Ball key={color} color={color} />
            ))}
            {showAnimation && swapBalls(colors).map((color) => (
              <Ball key={color} color={color} />
            ))}
          </AnimatePresence>
        </Flex>

        <Text
          mt="6"
          fontSize={{ base: '20px', md: '35px', lg: '55px' }}
          color="purple.400"
          className="pulse-animation"
          css={{
            fontFamily: 'Impact, sans-serif',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.5)',
            letterSpacing: '2px',
            fontWeight: 'bold',
          }}
        >
          {showAnimation && `${timer}`}
        </Text>

        {showWinningColor && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: "50px",
              textAlign: 'center',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            <Text color="gold" style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '10px' }}>Winning Color</Text>
            <Ball color={shuffledColor} style={{ marginTop: '10px' }} />
          </motion.div>
        )}
        <Input
          type="number"
          value={betAmount}
          onChange={(e) => setBetAmount(e.target.value)}
          placeholder="Enter bet amount"
          mt="4"
          borderColor="purple.300"
          bg="rgba(255, 255, 255, 0.1)"
          _placeholder={{ color: 'purple.200' }}
          color="purple.200"
          fontSize="20px"
          borderRadius="40px"
          size={{ base: 'sm', sm: 'lg' }}
        />

        <Button
          onClick={handleBet}
          colorScheme="purple"
          px={{ base: '6', md: '20' }}
          py={{ base: '4', md: '8' }}
          fontSize={{ base: 'md', md: 'lg' }}
          fontWeight="bold"
          mt="4"
          isDisabled={!betAmount || gameStarted}
        >
          {gameStarted ? 'Good Luck!' : 'Place Bet'}
        </Button>

        <Text color="red.400" mt="4">
          {message}
        </Text>

        <Tabs variant="soft-rounded" colorScheme="purple" mt="6">
          <TabList
            justifyContent="center"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Tab fontSize={{ base: 'sm', md: 'md' }}><FaRegListAlt /> &nbsp;Betting History</Tab>
            <Tab fontSize={{ base: 'sm', md: 'md' }}><FaInfoCircle /> &nbsp;Game Info</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {history.length === 0 ? (
                <Text>No betting history yet.</Text>
              ) : (
                history.map((entry, index) => (
                  <Box key={index} mb="4" p="4" borderRadius="md" bg="rgba(255, 255, 255, 0.1)">
                    <Text>Color: {entry.selectedColor}</Text>
                    <Text>Bet Amount: ₹{entry.betAmount}</Text>
                    <Text>Result: {entry.result}</Text>
                    <Text>{entry.outcome}</Text>
                  </Box>
                ))
              )}
            </TabPanel>
            <TabPanel textAlign="left">
              <Text fontSize={{ base: 'sm', md: 'md' }}>1. Bet on only one of the 7 available colors with a single amount.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>2. The color shuffle will start and stop automatically.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>3. You win if the shuffled color matches your chosen color.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>4. Payouts are based on your bet amount.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>5. Bets can’t be changed once the color shuffle starts.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>6. Enjoy fair play with an automatic and random color shuffle.</Text>
              <Text fontSize={{ base: 'sm', md: 'md' }}>7. The game is fair, as it uses a random number generator to ensure fair game for everyone</Text>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Box
        bg="#1E0342"
        p={4}
        borderRadius="md"
     
        mt="6"
        width={{ base: '85%', sm: '80%', md: '70%', lg: '60%' }}
      >
        <Heading
          as="h3"
          size={{ base: 'sm', md: 'md' }}
          textAlign="center"
          color="#FF6F6F"
        >
          Caution Note
        </Heading>
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          color="#FF4D4D"
          mt={4}
        >
          <b>Important:</b> Avoid navigating away or refreshing this page while placing bets to prevent errors or unregistered bets. We are not liable for issues caused by page refreshes or navigation; finalize all bets before leaving.
        </Text>
      </Box>
    </Flex>
  );
};
export default ColorPredictionGame;
