import React, { useState, useEffect } from 'react';
import { Box, Grid, GridItem, Text, Flex, Icon, Select, Spinner, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { MdErrorOutline } from 'react-icons/md';

const CreditTransaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('createdAt'); // Default sort by date

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`https://newproject3-0-3stp.onrender.com/account/${username}/history`);
        const creditTransactions = response.data.filter(tx => tx.type.toLowerCase() === 'deposit');
        setTransactions(creditTransactions);
        setLoading(false);
      } catch (error) {
        setError('No Credit Transactions Found.');
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const sortedData = transactions.sort((a, b) => {
    if (sortBy === 'createdAt') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sortBy === 'amount') {
      return b.amount - a.amount;
    }
    return 0;
  });

  return (
    <Box p={4}>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : error ? (
        <Flex direction="column" align="center" justify="center" h="200px">
          <Box color="gray.500" textAlign="center" mb={4}>
            <Icon as={MdErrorOutline} boxSize={8} color="red.500" />
            <Heading as="h3" size="md" mt={2}>{error}</Heading>
          </Box>
        </Flex>
      ) : transactions.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text fontSize="xl" fontWeight="bold" color="gray.500">
            No Credit Transactions Found.
          </Text>
        </Box>
      ) : (
        <Box>
          <Flex justifyContent="space-between" alignItems="center" mb={4} p={4} bg="white" borderRadius="lg" boxShadow="md">
            <Flex alignItems="center">
              <Text fontWeight="bold" mr={2}>Sort By:</Text>
              <Select value={sortBy} onChange={handleSortChange} width="150px">
                <option value="createdAt">Date</option>
                <option value="amount">Amount</option>
              </Select>
            </Flex>
          </Flex>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }}
            gap={6}
          >
            {sortedData.map((item, index) => (
              <GridItem key={index}>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    p={4}
                    bg="white"
                    boxShadow="lg"
                    _hover={{ transform: 'translateY(-4px)', boxShadow: 'xl' }}
                    position="relative"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      top="0"
                      right="0"
                      bg="blue.500"
                      color="white"
                      px={2}
                      py={1}
                      borderBottomLeftRadius="lg"
                      fontSize="xs" // Adjusted for smaller screens
                    >
                      Deposit
                    </Box>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text
                        as="span"
                        borderBottom="2px solid transparent"
                        _hover={{ borderBottomColor: 'teal.500' }}
                        fontWeight="bold"
                        fontSize="md" // Adjusted for smaller screens
                        color="teal.500"
                        mb={2}
                        pb={2}
                      >
                        {item.description}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontSize="sm">Date:</Text> {/* Adjusted font size */}
                      <Text fontSize="sm">{formatDate(item.createdAt)}</Text> {/* Adjusted font size */}
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontSize="sm">Amount:</Text> {/* Adjusted font size */}
                      <Text fontSize="sm">₹{item.amount}</Text> {/* Adjusted font size */}
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center" mt={2}>
                      <Text fontSize="sm">Status:</Text> {/* Adjusted font size */}
                      <Text fontSize="sm">{item.status}</Text> {/* Adjusted font size */}
                    </Flex>
                  </Box>
                </motion.div>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CreditTransaction;
